import { Button, Stack } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  GetPayableFeesArrangeBy,
  GetPayableFeesQueryFilters,
  GetPayableFeesQuerySort,
  PayableFee,
  useGetPayableFeesQuery,
} from '@schooly/api';
import { EyeIcon, GridBody, MainPageGrid, SkeletonRows } from '@schooly/style';
import { format, lastDayOfMonth } from 'date-fns';
import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { PayableFeeRow, PayableFeesHeader } from './PayableFeesGrid';

type ArrangedByPayableFeesGridProps = {
  count: number;
  filters: GetPayableFeesQueryFilters;
  arrangeByKey?: GetPayableFeesArrangeBy;
  arrangeByValue?: string | number;
  schoolId: string;
  sort?: GetPayableFeesQuerySort;
  query?: string;
  onChangeSort: (v: GetPayableFeesQuerySort) => void;
};

const PAGE_SIZE = 30;
const HEADER_OFFSET = 75;

export const ArrangedByPayableFeesGrid: FC<ArrangedByPayableFeesGridProps> = ({
  count,
  sort,
  filters: initialFilters,
  schoolId,
  arrangeByKey,
  arrangeByValue,
  onChangeSort,
}) => {
  const filters = useMemo(() => {
    if (!arrangeByKey || !arrangeByValue) return initialFilters;

    switch (arrangeByKey) {
      case FilterKeys.Month:
        const startDate = arrangeByValue + '';
        if (!startDate) return initialFilters;

        return {
          ...initialFilters,
          [FilterKeys.Date]: [
            startDate,
            format(lastDayOfMonth(new Date(startDate)), DEFAULT_DATE_FORMAT_FNS),
          ],
        };

      case FilterKeys.Payer:
      case FilterKeys.Product:
      case FilterKeys.DayPastDue:
        return {
          ...initialFilters,
          [arrangeByKey]: [arrangeByValue === undefined ? 'none' : arrangeByValue],
        };
      default:
        const _never: never = arrangeByKey;
        return _never;
    }
  }, [initialFilters, arrangeByKey, arrangeByValue]);

  const { data, isLoading, params, setParams, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetPayableFeesQuery(
      {
        school_ids: schoolId,
        page_size: PAGE_SIZE,
        sort,
        filters,
        query: '',
      },
      { refetchOnMount: 'always' },
    );

  useEffect(() => {
    setParams((params) => ({ ...params, filters, sort }));
  }, [sort, filters, setParams]);

  const entries = useMemo(
    () => data?.pages.reduce<PayableFee[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],
    [data?.pages],
  );

  return (
    <MainPageGrid
      bottomElement={
        hasNextPage && !isFetchingNextPage ? (
          <Stack mt={1} alignItems="center">
            <Button startIcon={<EyeIcon />} variant="text" onClick={() => fetchNextPage()}>
              <FormattedMessage id="action-ShowMoreButton" />
            </Button>
          </Stack>
        ) : undefined
      }
    >
      <PayableFeesHeader
        sort={params.sort}
        onChangeSort={onChangeSort}
        sx={{
          position: 'sticky',
          bgcolor: 'white',
          zIndex: 3,
          top: HEADER_OFFSET,
        }}
      />
      <GridBody>
        {entries?.map((entry) => (
          <PayableFeeRow payableFee={entry} />
        ))}
        {(isLoading || isFetchingNextPage) && (
          <SkeletonRows
            columnsCount={11}
            amount={Math.min(PAGE_SIZE, count - (entries?.length || 0))}
          />
        )}
      </GridBody>
    </MainPageGrid>
  );
};
