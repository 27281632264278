import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import {
  ChartBarData,
  FilterKeys,
  GetInvoicesReportingAggregatedResponse,
  GetPayableFeesArrangeBy,
  GetPayableFeesQueryFilters,
  InvoicesReportingAggregatedRow,
} from '@schooly/api';
import {
  Currencies,
  CURRENCY_SYMBOLS,
  PROPERTIES_TEXT_IDS,
  SchoolUserRole,
} from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { format } from 'date-fns';
import { TooltipComponentFormatterCallbackParams } from 'echarts';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { FormattedMessage, useIntl } from 'react-intl';

import { BarSelectProps, ChartBar, ChartBarProps } from './chartBar/ChartBar';
import { ChartsContainer } from './chartBar/ChartsContainer';
import { ChartTooltip } from './chartBar/ChartTooltip';
import { useGetLabel } from './chartBar/useGetLabel';
import { HSBColor } from './chartBar/utils';
import { useCharts } from './context/ChartsContext';
import {
  getPayableFeesStatusLabel,
  numberFormatter,
  payableFeesAxisValueFormatter,
  payableFeesSeriesLabelFormatter,
  payableFeesStatusColorPalette,
} from './utils';

const BAR_ITEM_LIMIT = 5;

export type PayableFeesChartsCustomGrid<T> = {
  row: InvoicesReportingAggregatedRow;
  currency: Currencies;
  filters: Partial<T>;
};

interface ChartsProps extends Omit<ChartBarProps, 'chartData' | 'getDataName'> {
  entityType: 'student' | 'staff';
  onOpenArrangeByDropdown?: () => void;
  onOpenCustomGrid: (v: PayableFeesChartsCustomGrid<GetPayableFeesQueryFilters> | null) => void;
  breakDownByOptions: FilterKeys[];
  arrangeBy: GetPayableFeesArrangeBy;
  filters: GetPayableFeesQueryFilters;
  query?: string;
  data?: GetInvoicesReportingAggregatedResponse;
  loading?: boolean;
}

export const PayableFeesChart: FC<ChartsProps> = ({
  entityType,
  filters,
  onOpenArrangeByDropdown,
  onOpenCustomGrid,
  arrangeBy,
  data,
  loading: loadingData,
}) => {
  const { $t, formatMessage } = useIntl();
  const {
    isChartsOpened,
    showZeroValues,
    breakDownBy,
    schoolId,
    chartSelectedElement,
    setChartSelectedElement,
    setBreakDownBy,
    onToggleChartsOpened,
    onToggleZeroValues,
  } = useCharts();

  const chartTooltip = useCallback((params: TooltipComponentFormatterCallbackParams): string => {
    if (Array.isArray(params)) {
      throw new Error('Array is not accepted in renderTooltip');
    }

    if (!params.value) {
      return '';
    }

    const seriesName = params.seriesName ?? '';
    const payableFeesSeriesName = getPayableFeesStatusLabel(seriesName) ?? seriesName;

    return renderToString(
      <ChartTooltip
        title={formatMessage({ id: payableFeesSeriesName })}
        value={numberFormatter(params.value as number)}
        backgroundColor={typeof params.color === 'string' ? params.color : undefined}
      />,
    );
  }, []);

  const { schoolProperties, isLoading: isLoadingSchoolProperties } = useSchoolProperties({
    userType: entityType === 'student' ? SchoolUserRole.Student : SchoolUserRole.Staff,
    schoolId,
    showReEnrollmentProperties: true,
  });

  const getDataName = useGetLabel({
    arrangeBy,
    schoolProperties,
  });

  const getBarName = useCallback(
    (name: string) => {
      switch (arrangeBy) {
        case FilterKeys.Month:
          return format(new Date(name), "MMM''yy");
        case FilterKeys.Product:
          return name;
        case FilterKeys.DayPastDue:
          return formatMessage({ id: `payableFees-${name}` });
        default:
          return name;
      }
    },
    [arrangeBy, data?.rows?.length],
  );

  useEffect(() => {
    setBreakDownBy(FilterKeys.FeeStatus);
  }, [arrangeBy, setBreakDownBy]);

  useEffect(() => {
    setChartSelectedElement(undefined);
    onOpenCustomGrid(null);
  }, [arrangeBy, filters, setChartSelectedElement, onOpenCustomGrid]);

  const [customColorPalette, setCustomColorPalette] = useState<Array<HSBColor>>([]);

  useEffect(() => {
    const res = data?.chartData.map(({ key }) => {
      return payableFeesStatusColorPalette.find(({ key: status }) => key === status)?.color;
    });
    setCustomColorPalette((res?.filter((el) => !!el) || []) as HSBColor[]);
  }, [data?.chartData]);

  const [chartData, hasZeroValues] = useMemo(() => {
    if (!data?.chartData.length) return [];

    let hasZeroValues = false;

    const arrangeByMapper: Record<number, boolean> = [];

    const arrangeByData = {
      dataKey: arrangeBy,
      data: data?.chartData?.[0]?.rows?.map(({ name, value }) => {
        return {
          name: getBarName(arrangeBy === FilterKeys.Month ? value : name),
          dataValue: value,
        };
      }),
    };

    const seriesData = data.chartData.map(({ key, rows }) => {
      const keyLabel = getPayableFeesStatusLabel(key);
      return {
        name: keyLabel ? formatMessage({ id: keyLabel }) : key,
        dataValue: key,
        dataKey: breakDownBy || null,
        data: rows.map(({ total }) => total),
      };
    });

    const arrangeByModified = arrangeByData.data?.filter((item, index) => {
      const total = seriesData.reduce((prev, curr) => prev + (curr.data[index] ?? 0), 0);

      if (total === 0) {
        hasZeroValues = true;
      }

      if (!showZeroValues && !total) {
        arrangeByMapper[index] = false;
        return false;
      }

      arrangeByMapper[index] = true;
      return true;
    });

    const normalizedData: ChartBarData = {
      arrange_by: {
        ...arrangeByData,
        data: arrangeByModified ?? [],
      },
      series: seriesData.map((item) => {
        return {
          ...item,
          data: item.data.filter((d, idx) => {
            return arrangeByMapper[idx];
          }),
        };
      }),
    };

    return [normalizedData, hasZeroValues];
  }, [arrangeBy, data, getBarName, showZeroValues, breakDownBy]);

  const onBarSelect = useCallback(
    ({ data: barData, bar }: BarSelectProps) => {
      const getFiltersFromBarData = (
        barData: BarSelectProps['data'],
      ): Partial<GetPayableFeesQueryFilters> => {
        const filters: Partial<Record<FilterKeys, any>> = {};
        if (!barData) return filters;

        for (const { dataKey, dataValue } of barData) {
          if (!dataKey) {
            return filters;
          }

          filters[dataKey] = [dataValue === null ? '' : dataValue];
        }

        return filters;
      };

      setChartSelectedElement((old) => {
        const isElementSelected =
          old?.dataIndex === bar.dataIndex && old?.seriesIndex === bar.seriesIndex;
        const newSelectedBar = isElementSelected ? undefined : bar;

        if (newSelectedBar && barData && data) {
          const barFilters = getFiltersFromBarData(barData);

          const selectedRow = data?.rows.find(
            ({ value, name }) =>
              getBarName((arrangeBy === FilterKeys.Month ? value : name) as string) ===
              newSelectedBar.name,
          );

          if (selectedRow) {
            onOpenCustomGrid({
              filters: { ...filters, ...barFilters },
              currency: data.currency,
              row: selectedRow,
            });
          }
        } else {
          onOpenCustomGrid(null);
        }

        return newSelectedBar;
      });
    },
    [filters, data, onOpenCustomGrid, setChartSelectedElement],
  );

  const legendData = useMemo(
    () => chartData?.series?.map(getDataName) ?? [],
    [chartData?.series, getDataName],
  );

  if (!isChartsOpened) return null;

  return (
    <ChartsContainer
      isEmpty={!!chartData?.series.every((s) => s.data.length === 0)}
      isLoading={loadingData || isLoadingSchoolProperties}
      onToggleChartsOpened={onToggleChartsOpened}
      arrangeBy={arrangeBy}
      onOpenArrangeByDropdown={onOpenArrangeByDropdown}
      zeroValuesToggler={
        hasZeroValues ? (
          <FormControlLabel
            control={<Switch checked={showZeroValues} onChange={onToggleZeroValues} />}
            label={<FormattedMessage id="showZeroValues" />}
            sx={{
              m: 0,
              color: showZeroValues ? 'text.primary' : 'text.secondary',
            }}
          />
        ) : null
      }
      arrangeByDropdown={
        arrangeBy ? (
          // TODO: uncomment when more options in breakdownBy are available
          // <ArrangeByDropdown
          //   onSelectOption={setBreakDownBy}
          //   options={breakDownByOptions?.filter((o) => o !== arrangeBy)}
          //   selectedOption={breakDownBy}
          //   label={$t({ id: 'brakeDownBy' })}
          //   onClear={() => setBreakDownBy(undefined)}
          //   onRemove={() => setBreakDownBy(undefined)}
          // />
          <Stack flexDirection="row" gap={0.5} alignItems="center">
            <Typography variant="h3" color="common.grey3">
              {$t({ id: 'brakeDownBy' }) + ':'}
            </Typography>

            {breakDownBy && (
              <Typography variant="h3">{$t({ id: PROPERTIES_TEXT_IDS[breakDownBy] })}</Typography>
            )}
          </Stack>
        ) : undefined
      }
      totalLabel={
        data?.total ? (
          <Stack direction="row" gap={1}>
            <Typography variant="h3" sx={{ fontSize: 15 }} color="common.grey2">
              Total:
            </Typography>

            <Typography variant="h3" sx={{ fontSize: 15 }} color="primary.main">
              {CURRENCY_SYMBOLS[data.currency]} {numberFormatter(data.total)}
            </Typography>
          </Stack>
        ) : undefined
      }
    >
      {chartData && customColorPalette && (
        <ChartBar
          barPosition={
            chartData.arrange_by.data.length < BAR_ITEM_LIMIT ? 'vertical' : 'horizontal'
          }
          key={breakDownBy}
          renderTooltip={chartTooltip}
          chartData={chartData}
          onBarSelect={onBarSelect}
          getDataName={getDataName}
          selectedBar={chartSelectedElement}
          legendData={legendData}
          showZeroValues={showZeroValues}
          customColorPalette={customColorPalette}
          customAxisValueFormatter={payableFeesAxisValueFormatter}
          customSeriesLabelFormatter={payableFeesSeriesLabelFormatter}
        />
      )}
    </ChartsContainer>
  );
};
